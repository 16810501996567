import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FacebookFooterIcon } from '../../../assets/images/footer/Facebook-Footer.svg';
import { ReactComponent as LogoWatermark } from '../../../assets/images/Logo-Watermark.svg';
import { ReactComponent as LogoFooter } from '../../../assets/images/footer/Logo-Footer.svg';
import { motion } from "framer-motion";
import AccessibilityControls from "../../utilities/AccessibilityControls";

const FooterSection: React.FC = () => {
  const [showAccessibilityModal, setShowAccessibilityModal] = useState(false);

  const toggleAccessibilityModal = () => {
    setShowAccessibilityModal(!showAccessibilityModal);
  };

  return (
    <div className="bg-wave-blue text-white">
      <div className="flex flex-col md:flex-row items-start px-4 md:px-8">
        <div className="flex flex-col md:flex-1 md:flex-row justify-between">
          <div className="py-8 w-full md:w-1/4">
            <a
              href="https://www.facebook.com/BayandBasinInsurance/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookFooterIcon className="fill-current text-white w-8 h-8 mb-4" />
            </a>
            <br />
            <Link to="/providers" className="font-maleo font-light hover:underline">
              Contact Carrier
            </Link>
            <br />
            <br />
            <span className="font-maleo font-light">
              Call Tina for <br />
              Complimentary Consultation:
            </span>
            <br />
            P: 925-708-1122
            <br />

            {/* CALIFORNIA INSURANCE LICENSE INFO */}
            <p className="mt-6">
              <strong>Bay and Basin Insurance Services</strong>
              <br />
              <span>California License #: 6005770</span>
              <br />
              <span>Business Address: 371 Main St, Salinas, CA 93901</span>
            </p>
            {/* END LICENSE INFO */}

            <p className="text-xs mt-4">
              © {new Date().getFullYear()} Bay and Basin Insurance Services
              <br />
              <Link to="/terms-of-service" className="hover:underline">
                Terms of Service &amp; Privacy Policy
              </Link>
              <br />
              <a href="#accessibility" onClick={toggleAccessibilityModal} className="hover:underline">
                Website Accessibility
              </a>
            </p>
            <br />
            <Link to="/crm/login" className="font-maleo font-light hover:underline">
              CRM Login
            </Link>
          </div>

          <div className="flex-1 flex justify-center items-center w-full md:w-1/4 p-2 mt-4 md:mt-0">
            <LogoWatermark className="h-auto w-full" style={{ maxWidth: "100%" }} />
          </div>
        </div>

        <div className="py-8 px-4 md:px-8 w-full md:w-1/2 mt-4 md:mt-0">
          <LogoFooter className="h-auto mb-4 px-12" />
          <div className="text-sea-blue">
            371 Main St, Salinas, CA 93901
            <br />
            P: 831-540-4583
            <br />
            F: 888-561-4927
          </div>
          <div className="mt-6 flex flex-row justify-center items-center">
            {["Home", "About Us", "Insurance Services", "Contact Us"].map((item, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, color: "#ffffff" }}
                transition={{ type: "spring", stiffness: 300 }}
                className="mr-6"
              >
                <Link
                  to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
                  className="font-maleo font-regular text-sea-blue hover:text-white"
                >
                  {item}
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {showAccessibilityModal && (
        <AccessibilityControls onClose={() => setShowAccessibilityModal(false)} />
      )}
    </div>
  );
};

export default FooterSection;